import React from "react";

const spots = [
  {
    name: "ビジネス",
    description: (
      <ul className="ml-3 px-3 list-disc list-outside">
        <li>クロスボーダー、中国内取引におけるビジネスプラン構築</li>
        <li>法制度、会計税務、外貨管理、税関管理などを踏まえた商流の提案</li>
      </ul>
    ),
  },
  {
    name: "登記関連サポート",
    description: (
      <ul className="ml-3 px-3 list-disc list-outside">
        <li>法人、駐在員事務所、分公司の設立、再編、清算サポート</li>
        <li>
          特殊ライセンス取得サポート（例：危険化学品経営許可証、危険化学品登記証、道路運輸経営許可証、食品経営許可証、化粧品経営許可証など）
        </li>
        <li>NVOCC登録、国際貨運代理企業登録、高新技術企業などの認定サポート</li>
      </ul>
    ),
  },
  {
    name: "税務・会計サポート",
    description: (
      <ul className="ml-3 px-3 list-disc list-outside">
        <li>記帳、税務申告代行</li>
        <li>出納業務、仕入発票認証、販売発票発行代行</li>
        <li>
          日本語財務諸表の作成、統計表の作成申告代行、増値税輸出還付申請、企業所得税、個人所得税の確定申告、非居住者の個人所得税申告代理、連合年度報告代行、年度監査報告のサポート
        </li>
        <li>対外送金許可（所管税務機関での備案）対応</li>
      </ul>
    ),
  },
  {
    name: "法務サポート",
    description: (
      <ul className="ml-3 px-3 list-disc list-outside">
        <li>契約書のリーガルチェック、リーガルオピニオン作成</li>
        <li>就業規則、労働契約書の作成、点検、修正</li>
        <li>
          訴訟など法的手段に基づく債権回収のサポート、債権債務に関する再構築・税務上の損金処理のサポート
        </li>
        <li>コンプライアンス管理など社内管理制度構築サポート</li>
        <li>現地法人に適用される中国法令リストの作成、メンテナンス</li>
        <li>知財関連（商標、特許の登記、権利侵害対応、訴訟サポート）</li>
      </ul>
    ),
  },
  {
    name: "人事・労務サポート",
    description: (
      <ul className="ml-3 px-3 list-disc list-outside">
        <li>駐在員関連</li>
        <ul className="ml-3 px-3 list-disc list-outside">
          <li>
            帯同家族を含めた就業許可・居留許可申請・延期・変更・抹消サポート
          </li>
          <li>中国ビザ申請前の招聘状申請サポート</li>
          <li>中国での社会保険加入サポート</li>
        </ul>
        <li>現地従業員関連</li>
        <ul className="ml-3 px-3 list-disc list-outside">
          <li>社会保険、住宅積立納付加入サポート</li>
          <li>
            従業員人員整理・雇用・解雇などのサポート（訴訟など法的対応を含む）
          </li>
        </ul>
      </ul>
    ),
  },
  {
    name: "物流・通関サポート",
    description: (
      <ul className="ml-3 px-3 list-disc list-outside">
        <li>HSコードの仮認定</li>
        <li>
          税関への危険化学品の安全特性鑑定報告書申請、特定化学品の輸送条件鑑定書の申請
        </li>
      </ul>
    ),
  },
  {
    name: "その他",
    description: (
      <ul className="ml-3 px-3 list-disc list-outside">
        <li>信用調査</li>
        <li>翻訳、通訳</li>
        <li>各種政府補助金の申請サポート</li>
        <li>上海市の優秀人材導入の申請（上海戸籍の申請）サポート</li>
      </ul>
    ),
  },
];

const SpotService = () => {
  return (
    <div className="mx-auto max-w-2xl">
      <div className="divide-y">
        {spots.map((s, index) => (
          <div key={index} className="py-5 md:py-10 space-y-3 md:space-y-5">
            <div className="font-sans md:text-lg text-center md:text-left">
              {s.name}
            </div>
            <div className="text-sm md:text-base leading-relaxed md:leading-loose">
              {s.description}
            </div>
          </div>
        ))}
      </div>
      <div className="leading-relaxed text-xs md:text-sm p-3 md:p-5 mt-5 md:mt-10 bg-cyan-100">
        上記はサービス内容の一例となります。
        <br />
        会員様の多様なニーズにお応えできるよう、日々サービス向上に努めておりますので、中国ビジネスに関する事であれば、何なりとお気軽にご相談下さい。
        <br />
        スポットサービスに関するお問い合せ・お申し込みはE-mail：
        <a href="mailto:info@mizuno-ch.com">info@mizuno-ch.com</a>
        までどうぞ。
      </div>
    </div>
  );
};

export default SpotService;
