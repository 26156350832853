import React from "react";

const services = [
  {
    name: "基本パッケージ",
    className: "bg-cyan-200",
    rmb: 2200,
    hkd: 2600,
    jpy: 35000,
    users: 2,
    description: (
      <>
        <div className="mb-2">
          「基本パッケージ」には以下のサービスが含まれます。
        </div>
        <div className="space-y-2 md:space-y-1">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/4 font-sans">Q&Aサービス</div>
            <div className="w-full md:w-3/4">
              中国ビジネスに関するご質問を、E-mail／電話にて回数無制限でお受け致します。
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/4 font-sans">
              規定邦訳データベース
            </div>
            <div className="w-full md:w-3/4">
              弊社WEBサイトの会員様限定コンテンツにて中国重要規定の日本語訳データベースをご利用頂けます。
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/4 font-sans">情報配信サービス</div>
            <div className="w-full md:w-3/4">
              中国ビジネス制度に関する情報、及びそれらの解説に関するレポートを、随時、配信致します。
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/4 font-sans">面談サービス</div>
            <div className="w-full md:w-3/4">
              月1時間の無料面談をお受け致します。※弊社各拠点オフィスでの面談となります。
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/4 font-sans">勉強会へのご招待</div>
            <div className="w-full md:w-3/4">
              弊社各拠点で開催する勉強会にご招待致します。
            </div>
          </div>
        </div>
      </>
    ),
  },
  {
    name: "基本パッケージプラス(A)",
    className: "bg-coral-200",
    rmb: 2450,
    hkd: 2900,
    jpy: 38000,
    users: 2,
    description: (
      <>
        <div className="mb-2">
          「基本パッケージプラス(A)」には「基本パッケージ」に以下のサービスが追加されます。
        </div>
        <div className="space-y-1">
          <div className="font-sans">
            水野コンサルタンシーグループ・チェイスネクストの中国ビジネスレポート
          </div>
          <div>
            チェイスネクストに掲載される、中国各分野の専門家が執筆するビジネスレポートが全て閲覧可能となります。中国のマクロ経済、政治政策、人事労務、会計税務など多方面を網羅しております。
          </div>
        </div>
      </>
    ),
  },
  {
    name: "基本パッケージプラス(B)",
    className: "bg-coral-200",
    rmb: 2360,
    hkd: 2790,
    jpy: 38000,
    users: 2,
    description: (
      <>
        <div className="mb-2">
          「基本パッケージプラス(B)」には「基本パッケージ」に以下のサービスが追加されます。
        </div>
        <div className="space-y-1">
          <div className="font-sans">中国ビジネス解説動画</div>
          <div>
            弊社グループ代表水野真澄が共同通信グループNNAで連載中の「中国ビジネス講座※2021年1月以降の全記事」に加え、中国のビジネス制度（外貨管理、税関、税務、会社管理制度など）にスポットをあてたシリーズや、最新ホットトピックなど中国ビジネスに役立つ内容を、水野本人が各回15分前後で解説する動画を全て視聴できます。
          </div>
        </div>
      </>
    ),
  },
  {
    name: "基本パッケージプラス(C)",
    className: "bg-coral-200",
    rmb: 2610,
    hkd: 3090,
    jpy: 41000,
    users: 2,
    description: (
      <>
        <div className="mb-2">
          「基本パッケージプラス(C)」には「基本パッケージ」に以下のサービスが追加されます。
        </div>
        <div className="font-sans">
          チェイスネクストの中国ビジネスレポート＋中国ビジネス解説動画
        </div>
      </>
    ),
  },
  {
    name: "プレミアパッケージ",
    className: "bg-cerise-200",
    rmb: 10000,
    hkd: 12000,
    jpy: 150000,
    users: 5,
    description:
      "「プレミアパッケージ」では、基本パッケージのサービスに加え、弊社代表水野真澄がご質問にお答えし、面談方式のコンサルティングも全て直接対応致します。各種資料受領の窓口として5名の担当者登録が可能となり、グループ企業数社で弊社のコンサルティングサービスをご利用いただく場合、情報の共有に有効なパッケージとなります。",
  },
  {
    name: "法務対応パッケージ",
    className: "bg-chestnut-200",
    rmb: 3600,
    hkd: 4300,
    jpy: 58000,
    users: 2,
    description:
      "「法務対応パッケージ」では、基本パッケージのサービスに加え、弊社と提携関係にある弁護士に法律相談が可能となります。更に一歩踏み込んだ法律の切り口からアドバイスを受けたい場合、有効なパッケージとなります。実際に作業が発生する場合は、別途見積もりとなります。",
  },
];

const ConsultingService = () => {
  return (
    <div className="mx-auto max-w-2xl">
      <div className="leading-relaxed md:leading-loose text-sm md:text-base">
        会員様は、中国本土および香港の法律や制度、政策に関する内容については、回数制限なしにご質問いただくことができ、ご相談分野については、税務・会計、人事労務、法務、知的財産権関連、税関関連、物流関連、外貨管理など、中国ビジネス全般が対象となります。
        <br />
        なお、各種手続の代行や個別の調査・作業が必要な案件につきましては、会員様を対象に、スポット案件として事前にお見積りのうえ対応させていただきます。
      </div>
      <div className="divide-y">
        {services.map((s, index) => (
          <div key={index} className="py-5 md:py-10 space-y-3 md:space-y-5">
            <div
              className={`font-sans md:text-lg text-center py-2 ${s.className}`}
            >
              {s.name}
            </div>
            <div className="font-sans text-sm md:text-base space-y-3">
              <div className="flex flex-col md:flex-row mb-2">
                <div className="w-full md:w-1/4">料金／月</div>
                <div className="w-full md:w-3/4">
                  <div>
                    {new Intl.NumberFormat("ja-JP").format(s.rmb)}人民元 （HKD
                    {new Intl.NumberFormat("ja-JP").format(s.hkd)}, JPY
                    {new Intl.NumberFormat("ja-JP").format(s.jpy)}）
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/4">登録可能人数</div>
                <div className="w-full md:w-3/4">{s.users}名</div>
              </div>
              <div className="font-serif text-xs md:text-sm font-extralight tracking-wide">
                {s.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConsultingService;
