import React from "react";
import Layout from "../../components/layout";
import H1 from "../../components/h1";
import Section from "../../components/section";
import SubTitle from "../../components/subtitle";
import ConsultingService from "../../components/service/consulting";
import SpotService from "../../components/service/spot";
import ServiceMenu from "../../components/service/menu";
import Registration from "../../components/service/registration";
import Seo from "../../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="サービス案内" />
      <main>
        <H1>サービス案内</H1>
        <ServiceMenu />
        <div className="bg-cyan-50 space-y-3 md:space-y-5 py-3 md:py-5">
          <Section>
            <div className="mx-auto max-w-2xl leading-relaxed md:leading-loose text-sm md:text-base">
              水野コンサルタンシーホールディングスは、中国ビジネスコンサルタント・水野真澄が代表を務めるコンサルティングファームです。
              <br />
              中国・ベトナムでビジネスを展開されている日本企業の皆様へ、組織再編、外貨決済、通関手続・コンプライアンス、保税品・設備関連、記帳・税務申告、国際課税、知的財産権関連、買収・撤退などをはじめ、高度化・複雑化する諸問題に対し、安心のトータルソリューションを提供いたします。
              <br />
              グループ各拠点は、日本語・中国語・英語に対応しており、言語面のストレスなくサービスをご利用いただけます。
            </div>
          </Section>
          <Section>
            <SubTitle id="consulting">コンサルティングサービス</SubTitle>
            <ConsultingService />
          </Section>
          <Section>
            <SubTitle id="spot">スポットサービス</SubTitle>
            <SpotService />
          </Section>
          <Section>
            <SubTitle id="registration">
              秘書役サービス（営業許可・ビザ申請包括請負いサービス）
            </SubTitle>
            <Registration />
          </Section>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
