import React from "react";

const Registration = () => {
  return (
    <div className="mx-auto max-w-2xl space-y-5">
      <div className="text-sm md:text-base leading-relaxed md:leading-loose">
        2010年度より会員様限定の新サービスを開始いたします。 <br />
        中国の駐在員事務所（代表処）は年に1回、必ず営業許可証の更新手続きを行わなければなりません。
        それ以外にも、住所や社名、代表者などの変更の度に、登記内容を修正する手続きが必要となります。
        水野コンサルティンググループでは、中国内において頻繁に発生する法人（代表処）登記に関連する手続きを年間定額制で代行いたします。
        <br />
        当プランでは駐在員の異動に伴う就業証やビザの申請にも対応しております。
        毎回の情報収集や資料作成といった負担を軽減するためのアウトソーシングサービスですので、ぜひご利用ください。
        <br />
        ご不明な点については、お気軽にお問い合わせください。
      </div>
      <div className="space-y-3 md:space-y-5">
        <div className="text-center bg-gray-100 font-sans py-1">
          水野コンサルティンググループ会員様限定・登記代行プラン
        </div>
        <div className="space-y-2 px-0 md:px-5 text-sm md:text-base">
          <div className="flex">
            <div className="w-1/4">対象形態</div>
            <div className="w-3/4">法人、代表処</div>
          </div>
          <div className="flex">
            <div className="w-1/4">対象地域</div>
            <div className="w-3/4">上海、広州、深セン</div>
          </div>
          <div className="flex">
            <div className="w-1/4">年間費用</div>
            <div className="w-3/4">
              上海、広州地域15,000元 深セン地域18,000元
            </div>
          </div>
          <div>※別途実費（翻訳費、行政手続費など）がかかります。</div>
        </div>
        <div className="px-0 md:px-5 font-sans underline">サービス内容</div>
        <ol className="space-y-2 px-0 md:px-5 text-sm md:text-base">
          <li>1. 首席/一般代表の変更手続き</li>
          <li>2. 一般代表の新規登録、抹消手続き</li>
          <li>3. 代表処登記の延長手続き</li>
          <li>4. 住所変更手続き（※同区内に限る）</li>
          <li>5. 社名変更手続き</li>
          <li>6. 就業証及び居留許可の申請/変更手続き（最大4名）</li>
        </ol>
        <div className="px-0 md:px-5 font-sans underline">担当</div>
        <div className="space-y-2 px-0 md:px-5 text-sm md:text-base">
          <div className="flex">
            <div className="w-1/3 md:w-1/4">水野商務上海</div>
            <div className="w-2/3 md:w-3/4">胡、周 Tel:86-21-6167-1310</div>
          </div>
          <div className="flex">
            <div className="w-1/3 md:w-1/4">水野商務広州</div>
            <div className="w-2/3 md:w-3/4">麦、楊 Tel:86-20-3877-1275</div>
          </div>
          <div>（日本語でお問い合わせください）</div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
